<template lang="pug">
  div
    base-snackbar(
      :errors.sync="budgetCodeStorePostErrors"
    )
    v-row
      v-col(cols="12")
        v-autocomplete(
          label="Project Code"
          v-model="projectCodeId"
          item-value="id"
          item-text="description"
          :items="projectCodeIndexList"
          :loading="projectCodeIndexGetting"
          :search-input.sync="searchProjectCode"
          clearable
          no-filter
        )
          tempalte(v-slot:selection="{ item }")
            span.primary--text {{ item.project_code }}
          template(v-slot:item="{ item }")
            span.primary--text {{ item.project_code }}
            small.ml-2.blue-grey--text {{ item.description }}
      v-col(cols="12")
        v-text-field(
          label="Description"
          v-model="description"
          :error-messages="budgetCodeStorePostErrors.description"
        )
      v-col(cols="12")
        v-btn(
          color="green"
          dense
          block
          dark
          :loading="budgetCodeStorePosting"
          @click="storeBudgetCode"
        )
          span Submit
</template>
<script>
import budgetCodeRepository from '@/repositories/budget-code.repository'
import projectCodeRepository from '@/repositories/project-code.repository'
import VueRequestHandler from '@/libs/classes/VueRequestHandler.class'
import { requestVars } from '@/libs/api-helper.extra'

const [budgetCodeStoreVars, budgetCodeStoreVarNames] = requestVars({ identifier: 'budget-code-store', request: 'post', hasData: false })
const [projectCodeIndexVars, projectCodeIndexVarNames] = requestVars({ identifier: 'project-code-index', pagination: true })

const projectCodeIndexHandler = new VueRequestHandler(null, projectCodeIndexVarNames, {}, true)
const budgetCodeStoreHandler = new VueRequestHandler(null, budgetCodeStoreVarNames)

const inputVars = () => ({
  description: null,
  projectCodeId: null,
})

export default {
  name: 'BudgetCodeCreate',
  data() {
    return {
      ...inputVars(),
      ...budgetCodeStoreVars,
      ...projectCodeIndexVars,
      searchProjectCode: null,
    }
  },
  created() {
    this.getProjectCodes()
  },
  watch: {
    searchProjectCode() {
      this.getProjectCodes()
    },
  },
  methods: {
    storeBudgetCode() {
      const handler = budgetCodeStoreHandler
      const repository = budgetCodeRepository.store
      const data = {
        description: this.description,
        project_code_id: this.projectCodeId,
      }
      
      handler.setVue(this)
      handler.execute(repository, [data], () => { this.reset() })
    },
    getProjectCodes() {
      const handler = projectCodeIndexHandler
      const repository = projectCodeRepository.index
      const params = {
        search: this.searchProjectCode,
      }
      handler.setVue(this)
      handler.execute(repository, [params])
    },
    reset() {
      const defaults = inputVars()
      Object.keys(defaults)
        .forEach(key => {
          this[key] = defaults[key]
        })
    },
  },
}
</script>
